.services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}


.service {
    text-align: center;
    width: 45%;
    max-width: 400px;
    background-color: rebeccapurple;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin: auto 50px;
}

.service i {
    font-size: 36px;
    color: #007bff;
}

.service h3 {
    font-size: 20px;
    margin: 10px 0;
    color: goldenrod;
}

.service p {
    font-size: 16px;
    color: white;
}

.services-header {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
    
}

.services-header i {
    font-size: 36px;
    color: #007bff;
    margin-right: 10px;
}

.services-header h2 {
    font-size: 50px;
    margin: 10px 0;
    color: red;
    margin-right: 10px;
}

.services-container{
    margin-top: 100px;
}

@media screen and (max-width:800px) {
        .service{
            width: 100%;
            margin: auto 10px;
        }
}

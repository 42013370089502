.about-container {
  padding: 20px;
}

/* About content styles */
.about-content {
  display: flex;
  flex-direction: row; /* Display the content side by side */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
}

/* About text styles */
.about-text {
  max-width: 600px;
  margin-right: 20px; /* Add spacing between text and image */
}

.about-text h1 {
  font-size: 24px;
}

.about-text p {
  font-size: 16px;
}

/* About image styles */
.about-image img {
  max-width: 200px; /* Adjust the image size as needed */
  height: auto;
  border-radius: 50%; /* Make the image round */
}

/* Additional styles for rounded images */
.round-image {
  border: 2px solid #333; /* Optional: Add a border to the round image */
}



.contact-container {
  text-align: center;
  padding: 20px;
}

/* Contact form styles */
.contact-form {
  max-width: 600px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

textarea {
  resize: vertical;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
}




.projects-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;

  }
  
  /* Project grid styles */
  .project-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 3fr)); /* Create a responsive grid with 3 projects per row */
    gap: 20px;
  }
  
  /* Project styles */
  .project {
    border: 1px solid #ddd;
    padding: 20px;
  }
  
  .project h3 {
    font-size: 20px;
    text-align: center;
    color: goldenrod;
  }
  
  .project video {
    max-width: 100%;
    height: auto;
    margin-top: 10px;
    text-align: center;
  }
  
  .project p {
    font-size: 16px;
    margin-top: 10px;
    text-align: center;
    color: white;
  }
  
  /* Project buttons styles */
  .project-buttons {
    margin-top: 10px;
  }
  
  .project-buttons a {
    text-decoration: none;
  }
  .project-buttons {
    display: flex; /* Change display to flex to align buttons in the same line */
    justify-content: space-between;
  }
  
  .project-buttons button {
    margin-right: 10px;
    padding: 12px 20px; /* Increased padding for a more pronounced 3D effect */
    background: linear-gradient(to right, black, gold, #ff4500);
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 4px; /* Rounded corners for a softer appearance */
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Adding box-shadow for the 3D effect */
    transition: transform 0.2s ease; /* Adding transition for smoother hover effect */
  }
  
  .project-buttons button:hover {
    transform: translateY(-2px); /* Lift the button slightly on hover */
  }
  
  
  .project-buttons button:hover {
    transform: translateY(-2px); /* Lift the button slightly on hover */
  }
  

  .project-header{
    text-align: center;
  }

  .project-header h2{
    color: red;
    font-size: 50px;
  }

 
  
  /* Responsive styles */
  @media screen and (max-width: 768px) {
    .project-grid {
      grid-template-columns: 1fr; /* Display one project per row on smaller screens */
    }
    .project{
      margin: auto 14px;
    }

   .project-buttons{
      display: flex;
    
   }
  }
  
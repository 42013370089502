.skills-container {
    text-align: center;
    padding: 20px;
  }
  
  /* Skill images container styles */
  .skills-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1px;
  }
  
  /* Individual skill styles */
  .skill {
    text-align: center;
  }
  
  .skill img {
    max-width: 100px; /* Adjust the image size as needed */
    height: auto;
  }
  
  .skill p {
    font-size: 16px;
    margin-top: 10px;
  }

  .skill-header h2{
        color: red;
        text-align: center;
        font-size: 50px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  }

  .icon-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
    align-items: center;
    justify-content: center;
    font-size: 48px;
    color: greenyellow; /* Icon color */
}

.icon-grid i {
    text-align: center;
    margin: 5px;
}

/* Style icons on hover, change color */
.icon-grid i:hover {
    color: #ff5733;
}

@media screen and (max-width: 768px) {
    .icon-grid {
        grid-template-columns: repeat(3, 1fr); /* 3 columns for smaller screens */
        font-size: 36px; /* Smaller icon size */
    }
}

 
  
.achievement {
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    text-align: center;
    padding: 20px;
    margin: auto 30px;
}



.achievement-header i {
    font-size: 36px;
    color: #007bff;
    margin-right: 10px;
}

.achievement-header h2 {
    color: red;
}

.achievements-container {
    display: flex;
    justify-content: space-between;
}

.achievement-column {
    width: 48%; /* Two columns with a small gap between them */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.achievement-item {
    background-color: burlywood;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    text-align: left;
}

.achievement-item i {
    font-size: 24px;
    color: #007bff;
    margin-right: 10px;
}

.achievement-item h3 {
    font-size: 20px;
    margin: 10px 0;
    color: goldenrod;
    font-weight: bolder;
}

.achievement-item ul {
    list-style-type: disc;
    padding-left: 20px;
}

.achievement-item ul li {
    font-size: 16px;
    margin: 5px 0;
    color: white;
}

/* New CSS for mobile responsiveness */
@media screen and (max-width: 767px) {
   

    .achievement-header h2 {
        font-size: 25px;
        color: red;
    }

    .achievements-container {
        display: block;
        margin: auto 10px;
    }

    .achievement-column {
        width: 120%; /* Take up the full width for mobile */
        display: block;
        margin: auto -28px;
    }

   

  

   


   
}


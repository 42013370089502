body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('../public/background.jpg');
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Projects container styles */
.projects-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* Project styles */
.project {
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
}

.project h3 {
  font-size: 20px;
}

.project img {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}

.project p {
  font-size: 16px;
  margin-top: 10px;
}

/* Project buttons styles */
.project-buttons {
  margin-top: 10px;
}

.project-buttons a {
  text-decoration: none;
}

.project-buttons button {
  margin-right: 10px;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .project {
    display: block;
  }
}

